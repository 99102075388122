import {
  BaseDiv,
  BodyDiv,
  ButtonDiv,
  FirmwareHeaderDiv,
  HeaderDiv,
  InfoDiv,
  KsafeInstructionsDisc,
  KsafeInstructionsDiv,
  KsafeInstructionsImgDiv,
  KSUpdateContainer,
  LatestVersion,
  LogoImg,
  ModalDiv,
  NoteCardDiv,
  NoteDiscriptionDiv,
  NoteVersionDiv,
  ShadowDiv,
  UpdateFwHeaderDiv,
  UpdateHistoryDiv,
  UpdateHistoryHeaderItem,
} from './styles';
import fus from 'services/fus';
import { Note } from 'services/fus';
import LogoSvg from '../../assets/kleverSafeLogo.svg';
import ksImage from '../../assets/ksafe-hero.png';
import instructions from '../../assets/ksafe_instructions.svg';

import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { ksafe } from '@klever/extension';
import { ConnectionFail } from 'components/connectionFailModal/intex';
import { WaitingForConnect } from 'components/WaitingForConnect';
import { EnterBootModeSoft } from 'components/EnterBootModeSoft';
import { IHwGetInfoResponse } from '@klever/extension/lib/types/dtos';
import { LoadingImg } from 'components/loadingComponents';
import { UpdatingModal } from 'components/UpdatingModal';
import { WarningVersionModal } from 'components/WaningVersionModal';
import { ProceedWithUpdateModal } from 'components/ProceedWithUpdateModal';
import { Retryer } from 'react-query/types/core/retryer';

enum UpdateStatus {
  Landing,
  NotStarted,
  Started,
  Permission,
  Finish,
}

enum ModalStatus {
  modalHide,
  askLed,
  enterBootModeSoftware,
  enterBootModeHardware,
  waitingForConnect,
  waitUserGesture,
  connectionFailed,
  updateStarted,
  updateFailed,
  updateFinished,
  waningVersion,
}

enum ItemsSelect {
  fwUpdateInstructions,
  updateHistory,
}

const KleverSafeUpdatePage: React.FC = () => {
  const [modalStatus, setModalStatus] = useState<ModalStatus>(
    ModalStatus.modalHide,
  );
  const [status, setStatus] = useState<UpdateStatus>(UpdateStatus.Landing);
  const [info, setInfo] = useState<IHwGetInfoResponse>();
  const [notes, setNotes] = useState<Note[]>();
  const [lastNote, setLastNote] = useState<Note>();
  const [isLoaded, setLoaded] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [blink, setBlink] = useState(false);
  const [selectedItem, setSelected] = useState<ItemsSelect>(
    ItemsSelect.fwUpdateInstructions,
  );

  const checkLoaded = () => {
    if (isLoaded) {
      return;
    }

    if (notes == undefined) {
      return;
    }
    try {
      window.hwInformRead(new Uint8Array());
      setLoaded(true);
    } catch {
      setTimeout(() => {
        checkLoaded();
      }, 1000);
    }
  };

  checkLoaded();

  if (notes == undefined) {
    fus.getLatestNotes(3).then(res => {
      setNotes(res);
    });
  }

  if (lastNote == undefined) {
    fus.getLatestNote().then(res => {
      setLastNote(res);
    });
  }

  const openConnection = async () => {
    setModalStatus(ModalStatus.waitingForConnect);
    await ksafe.initKsafe();
    try {
      await ksafe.hwClose();
    } catch {}
    await ksafe.hwOpen();
  };

  const getInfo = async () => {
    const infog = await ksafe.hwGetInfo();
    console.log(infog);
    setInfo(infog);
    return infog;
  };

  const startFirmwareUpdate = async () => {
    setStatus(UpdateStatus.Started);
    setModalStatus(ModalStatus.updateStarted);
    setPercentage(0);
    await ksafe.hwFirmwareUpdate(
      fus.getLatestFirmwareUrl(),
      (act: number, tot: number) => {
        const call = async () => {
          const p = Math.floor((act * 1000) / tot);
          if (act % 80 == 0) {
            setBlink(true);
          } else if (act % 82 == 0) {
            setBlink(false);
          }
          if (p % 1 == 0) {
            setPercentage(p / 10);
          }
        };
        call();
      },
    );
    await sleep(10000);
    /* await openConnection();
    const info = await getInfo();
    if (info.version != lastNote?.version) {
      throw 'firmware fail';
    }
    */
    setStatus(UpdateStatus.Finish);
    setModalStatus(ModalStatus.updateFinished);
    await sleep(5000);
    setStatus(UpdateStatus.NotStarted);
    setModalStatus(ModalStatus.modalHide);
  };

  const prepareFirmwareUpdate = async () => {
    const infog = await getInfo();
    if (infog!.version != 'BOOT MODE') {
      console.log('enter');
      setModalStatus(ModalStatus.enterBootModeSoftware);
      await enterBootMode();
      setModalStatus(ModalStatus.waitUserGesture);
      return;
    }
    setModalStatus(ModalStatus.modalHide);
    return await startFirmwareUpdate();
  };

  const enterBootMode = async () => {
    await ksafe.hwEnterbootMode();
  };

  const getLatestVersion = () => {
    if (lastNote == undefined) {
      return '';
    }

    return lastNote.version;
  };

  const KleverSafeHeader: React.FC = () => {
    return (
      <>
        <HeaderDiv>
          <div>
            <LogoImg src={LogoSvg}></LogoImg>
            <a href="https://kleversafe.io/">Go to KleverSafe store</a>
          </div>
          <div>
            <h2>Need support?</h2>
            <a href="https://klever.zendesk.com/hc/en-us">Open a ticket</a>
          </div>
        </HeaderDiv>
      </>
    );
  };

  const InitFirmwareUpdate = async () => {
    try {
      if (modalStatus === ModalStatus.waningVersion) {
        setModalStatus(ModalStatus.modalHide);
        const infog = await getInfo();
        await prepareFirmwareUpdate();
        return;
      }

      await openConnection();
      // Route to old bootloaders
      if (window.location.pathname == '/old') {
        return await startFirmwareUpdate();
      }
      const infog = await getInfo();
      console.log(infog);
      console.log(lastNote);
      setStatus(UpdateStatus.NotStarted);

      if (compareVersions(lastNote?.version, infog?.version) < 0) {
        setModalStatus(ModalStatus.modalHide);
        await prepareFirmwareUpdate();
      } else {
        setModalStatus(ModalStatus.waningVersion);
      }
    } catch (e) {
      setModalStatus(ModalStatus.connectionFailed);
      setStatus(UpdateStatus.NotStarted);
      console.log(e);
    }
  };
  const KleverSafeUpdateContainer: React.FC = () => {
    return (
      <KSUpdateContainer>
        <h1>
          KleverSafe <br />
          Update
        </h1>
        <h2>
          Welcome to KleverSafe firmware <br />
          update page
        </h2>
        <ButtonDiv
          onClick={async () => {
            await InitFirmwareUpdate();
          }}
        >
          <h1>Connect Wallet</h1>
        </ButtonDiv>
        <LatestVersion>
          <h1>LATEST VERSION:</h1>
          <h2>{getLatestVersion()}</h2>
        </LatestVersion>
      </KSUpdateContainer>
    );
  };

  const UpdateHistory: React.FC<{ notes: Note[] }> = ({ children, notes }) => {
    const getNotes = () => {
      if (notes == undefined) {
        return <></>;
      }

      const elems: JSX.Element[] = [];
      for (let i = 0; i < notes.length; i++) {
        elems.push(NoteCard(notes[i]));
      }

      return elems.reverse();
    };

    const UpdateHistorySelected = (show: boolean) => {
      if (!show) {
        return <></>;
      }
      return (
        <>
          <FirmwareHeaderDiv>
            <h1>FIRMWARE VERSION</h1>
            <h2>SPECIFICATIONS</h2>
          </FirmwareHeaderDiv>
          {getNotes()}
        </>
      );
    };

    const FirmwareInstructionsSelected = (show: boolean) => {
      if (!show) {
        return <></>;
      }
      return (
        <>
          <KsafeInstructionsDiv>
            <KsafeInstructionsDisc>
              <h1>
                • Connect the klever Safe to the PC or MAC using a usb-c cable
              </h1>
              <h1>• Make sure the OS recognizes the device</h1>
              <h1>{'• Click on the "connect wallet" button'}</h1>
              <h1>• Proceed with the update</h1>
            </KsafeInstructionsDisc>
          </KsafeInstructionsDiv>
        </>
      );
    };

    return (
      <UpdateHistoryDiv>
        <UpdateFwHeaderDiv>
          <UpdateHistoryHeaderItem
            selected={selectedItem == ItemsSelect.fwUpdateInstructions}
            onClick={() => {
              setSelected(ItemsSelect.fwUpdateInstructions);
            }}
          >
            <h1>How to update my firmware?</h1>
          </UpdateHistoryHeaderItem>
          <UpdateHistoryHeaderItem
            selected={selectedItem == ItemsSelect.updateHistory}
            onClick={() => {
              setSelected(ItemsSelect.updateHistory);
            }}
          >
            <h1>Update History</h1>
          </UpdateHistoryHeaderItem>
        </UpdateFwHeaderDiv>
        {UpdateHistorySelected(selectedItem == ItemsSelect.updateHistory)}
        {FirmwareInstructionsSelected(
          selectedItem == ItemsSelect.fwUpdateInstructions,
        )}
      </UpdateHistoryDiv>
    );
  };

  const NoteCard = (notes: Note | undefined) => {
    if (notes == undefined) {
      return <></>;
    }
    const version = notes!.version;
    let chains = '';
    if (notes!.chains != null && notes!.chains.length > 0) {
      chains = '-New chains: ';
      for (let i = 0; i < notes!.chains.length; i++) {
        chains += notes!.chains[i];
        if (i < notes!.chains.length - 1) {
          chains += ', ';
        }
      }
    }

    const features: JSX.Element[] = [];
    if (notes!.features != null) {
      for (let i = 0; i < notes!.features.length; i++) {
        features.push(<br key={'break-note-feat' + notes.version + '-' + i} />);
        features.push(
          <h1 key={'h1-note-feat' + notes.version + '-' + i}>
            -{notes!.features[i]}
          </h1>,
        );
      }
    }

    if (notes!.bugs != null) {
      for (let i = 0; i < notes!.bugs.length; i++) {
        features.push(<br key={'break-note-bugs' + notes.version + '-' + i} />);
        features.push(
          <h1 key={'h1-note-bugs' + notes.version + '-' + i}>
            -{notes!.bugs[i]}
          </h1>,
        );
      }
    }

    return (
      <NoteCardDiv key={'note' + notes.version}>
        <NoteVersionDiv>
          <h1>Version {version}</h1>
        </NoteVersionDiv>
        <NoteDiscriptionDiv>
          <h1>{chains}</h1>
          {features}
        </NoteDiscriptionDiv>
      </NoteCardDiv>
    );
  };

  return (
    <>
      <BaseDiv isVisible={isLoaded}>
        <ShadowDiv Shadow={modalStatus != ModalStatus.modalHide}>
          <KleverSafeHeader />
          <BodyDiv>
            <InfoDiv>
              <KleverSafeUpdateContainer />
              <img src={ksImage}></img>
            </InfoDiv>
            <UpdateHistory notes={notes!} />
          </BodyDiv>
        </ShadowDiv>
        {WaitingForConnect(modalStatus == ModalStatus.waitingForConnect)}
        {ConnectionFail(
          '',
          modalStatus == ModalStatus.connectionFailed,
          async () => {
            setStatus(UpdateStatus.Landing);
            setModalStatus(ModalStatus.modalHide);
          },
        )}
        {ConnectionFail(
          'Update failed',
          modalStatus == ModalStatus.updateFailed,
          async () => {
            setStatus(UpdateStatus.Landing);
            setModalStatus(ModalStatus.modalHide);
          },
        )}
        {EnterBootModeSoft(modalStatus == ModalStatus.enterBootModeSoftware)}
        <UpdatingModal
          percentage={percentage}
          isBlink={blink}
          isVisible={status == UpdateStatus.Started}
          version={getLatestVersion()}
          isDone={modalStatus == ModalStatus.updateFinished}
        ></UpdatingModal>
        <WarningVersionModal
          isVisible={modalStatus == ModalStatus.waningVersion}
          yes={async () => await InitFirmwareUpdate()}
          no={() => {
            setModalStatus(ModalStatus.modalHide);
            setStatus(UpdateStatus.NotStarted);
          }}
        />
        <ProceedWithUpdateModal
          show={modalStatus == ModalStatus.waitUserGesture}
          callback={async () => {
            await openConnection();
            setModalStatus(ModalStatus.modalHide);
            return await startFirmwareUpdate();
          }}
        ></ProceedWithUpdateModal>
      </BaseDiv>
      <LoadingImg show={!isLoaded} />
    </>
  );
};

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function compareVersions(
  versionA: string | undefined,
  versionB: string | undefined,
): number {
  console.log(versionA);
  console.log(versionB);
  if (!isValidVersion(versionA) || !isValidVersion(versionB)) {
    return -3;
  }

  const versionAArray = versionA!.split('.').map(num => parseInt(num));
  const versionBArray = versionB!.split('.').map(num => parseInt(num));

  for (let i = 0; i < 3; i++) {
    if (versionAArray[i] > versionBArray[i]) {
      return -1;
    } else if (versionAArray[i] < versionBArray[i]) {
      return 1;
    }
  }

  return 0;
}

function isValidVersion(version: string | undefined): boolean {
  if (version == undefined) {
    return false;
  }
  const regex = /^\d+\.\d+\.\d+$/;
  return regex.test(version);
}

export default KleverSafeUpdatePage;
